// Generated by Framer (28b2919)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Image from "https://framerusercontent.com/modules/4FYXL5lQz9cgJ2BJkaNV/73UsIpa97froefZwqzz9/Image.js";
const ImageFonts = getFonts(Image);

const cycleOrder = ["j1dIS_LA8"];

const variantClassNames = {j1dIS_LA8: "framer-v-t90k53"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({cover, height, id, width, ...props}) => { return {...props, O0j8X1INB: cover ?? props.O0j8X1INB} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;cover?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, O0j8X1INB, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "j1dIS_LA8", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pJm67", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-t90k53", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"j1dIS_LA8"} ref={ref} style={{...style}}><motion.div className={"framer-1e8psyl-container"} data-framer-name={"Image2"} layoutDependency={layoutDependency} layoutId={"ldHhi2Yn1-container"} name={"Image2"} transformTemplate={transformTemplate}><Image alt={""} height={"100%"} id={"ldHhi2Yn1"} image={toResponsiveImage(O0j8X1INB)} layoutId={"ldHhi2Yn1"} name={"Image2"} newTab padding={0} radius={0} shadowOptions={{shadowBlur: 4, shadowColor: "rgba(0, 0, 0, 0.2)", shadowEnabled: false, shadowX: 0, shadowY: 2}} style={{width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pJm67 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pJm67 .framer-1om7b3v { display: block; }", ".framer-pJm67 .framer-t90k53 { height: 300px; overflow: visible; position: relative; width: 200px; }", ".framer-pJm67 .framer-1e8psyl-container { flex: none; height: auto; left: 50%; position: absolute; top: 50%; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"O0j8X1INB":"cover"}
 */
const FramerDuPddJozf: React.ComponentType<Props> = withCSS(Component, css, "framer-pJm67") as typeof Component;
export default FramerDuPddJozf;

FramerDuPddJozf.displayName = "image";

FramerDuPddJozf.defaultProps = {height: 300, width: 200};

addPropertyControls(FramerDuPddJozf, {O0j8X1INB: {title: "Cover", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerDuPddJozf, [...ImageFonts])